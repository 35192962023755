export const addressNotBlank = address => !!address.street || !!address.locality || !!address.city;

export const formatAddress =
    address => 
        addressNotBlank(address) ?
        `${address.street || ''}${!!address.street ? ', ' : ''}${address.number || ''}${!!address.street && !address.number ? 'S/N' :''}` + 
        `${!!address.street? ' - ' : ''}${address.locality || ''}${!!address.locality ? ', ': ''}${address.city || ''}` +
        ` - ${address.stateInitials || ''}, ${address.zipCode || ''}` :
            'Não foi possivel recuperar a localização';

export const debounce = (miliseconds, callback) => {
    let timer;
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => callback(...args), miliseconds)
    }
}
