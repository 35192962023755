import React, { useState } from "react";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import Dots from "./Dots";

const daysOfWeek = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
  "Domingo",
];
const hours = Array.from(new Array(25), (_, index) => index);
function chooseColor({ data: { value } }) {
  return value <= 2
    ? "lightsalmon"
    : value <= 5
    ? "salmon"
    : value <= 7
    ? "lightcoral"
    : value <= 10
    ? "indianred"
    : value <= 12
    ? "crimson"
    : value <= 15
    ? "red"
    : value <= 17
    ? "firebrick"
    : "darkred";
}

export default ({ behaviors }) => {
  const [filter, setFilter] = useState("");
  const opts = behaviors.map((behavior) => {
    behavior.total = behavior.frequencyMap
      .map(({ frequency }) =>
        Object.values(frequency).reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0);
    return behavior;
  });
  const total = opts.reduce((a, b) => a + b.total, 0);
  return (
    <>
      <Row>
        <Col md={{ offset: 1 }}>
          <UncontrolledDropdown>
            <DropdownToggle caret>
              {opts
                .filter(({ device }) => device === filter)
                .map(
                  ({ device, neighborhood, locality, city, total, deviceProperties }) =>
                    `${locality}, ${neighborhood} - ${city}(${device} ${deviceProperties}, - ${total})`
                )[0] || `Todas - ${total}`}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                disabled={filter === ""}
                onClick={() => setFilter("")}
              >
                Todas - {total}
              </DropdownItem>
              {opts
                .sort((a, b) => b.total - a.total)
                .map(({ device, neighborhood, locality, city, total, deviceProperties }) => (
                  <DropdownItem
                    key={device}
                    disabled={filter === device}
                    onClick={() => setFilter(device)}
                  >
                    {locality}, {neighborhood} - {city}<small>({`${device}${deviceProperties? ', ' + deviceProperties: ''}`} - {total})</small>
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row>
        <Col style={{ height: "700px" }}>
          <ResponsiveSwarmPlot
            isInteractive
            data={behaviors
              .filter(({ device }) => filter === "" || filter === device)
              .flatMap(({ device, frequencyMap }) =>
                frequencyMap.flatMap(({ dayOfWeek, frequency }) =>
                  Object.entries(frequency).map(([hour, value]) => ({
                    id: `${daysOfWeek[dayOfWeek - 1]}-${hour}`,
                    group: daysOfWeek[dayOfWeek - 1],
                    hour: parseInt(hour.substring(0, 2)),
                    value,
                  }))
                )
              )
              .reduce((acc, curr) => {
                const found = acc.find(({ id }) => id === curr.id);
                if (found) {
                  found.value += curr.value;
                } else {
                  acc.push(curr);
                }
                return acc;
              }, [])}
            groups={daysOfWeek}
            size={15}
            colors={chooseColor}
            value="hour"
            valueScale={{
              type: "linear",
              min: 0,
              max: 24,
              reverse: true,
            }}
            axisRight={null}
            axisTop={null}
            gridYValues={hours}
            enableGridX={false}
            axisLeft={{
              orient: "bottom",
              format: (h) => `${h}h`,
              tickValues: hours,
              tickSize: 10,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Hora do dia",
              legendPosition: "end",
              legendOffset: -55,
            }}
            layers={["grid", "axes", Dots, "mesh"]}
            margin={{
              top: 40,
              right: 50,
              bottom: 40,
              left: 50,
            }}
            borderColor={{
              from: "color",
              modifiers: [
                ["darker", 0.6],
                ["opacity", 0.5],
              ],
            }}
          />
        </Col>
      </Row>
    </>
  );
};
