import React, { useMemo, createElement } from "react";
import { useTooltip, BasicTooltip } from "@nivo/tooltip";

const Dot = ({ node, onMouseEnter, onMouseMove, onMouseLeave }) => {
  const {
    data: { value },
    x,
    y,
  } = node;
  return (
    <g
      transform={`translate(${x},${y+10})`}
      onMouseEnter={(event) => onMouseEnter(node, event)}
      onMouseMove={(event) => onMouseMove(node, event)}
      onMouseLeave={(event) => onMouseLeave(node, event)}
    >
      <text
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          cursor: "pointer",
          fontSize: 16,
          fontWeight: 800,
        }}
      >
        {value}
      </text>
    </g>
  );
};

const Dots = ({ nodes, tooltip }) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const handleMouseEnter = useMemo(() => {
    return (node: ComputedDatum<RawDatum>, event: MouseEvent) => {
      const {
        data: { value, hour },
        color,
      } = node;
      const Tooltip = () => (
        <BasicTooltip
          id={`${hour}h-${hour + 1}h`}
          value={value}
          color={color}
          enableChip
        />
      );
      showTooltipFromEvent(createElement(Tooltip, node), event);
    };
  }, [showTooltipFromEvent]);

  const handleMouseMove = useMemo(() => {
    return (node: ComputedDatum<RawDatum>, event: MouseEvent) => {
      const {
        data: { value, hour },
        color,
      } = node;
      const Tooltip = () => (
        <BasicTooltip
          id={`${hour}h/${hour + 1}h`}
          value={value}
          color={color}
          enableChip
        />
      );
      showTooltipFromEvent(createElement(Tooltip, node), event);
    };
  }, [showTooltipFromEvent]);

  const handleMouseLeave = useMemo(() => {
    return (node: ComputedDatum<RawDatum>, event: MouseEvent) => {
      hideTooltip();
    };
  }, [hideTooltip]);

  return nodes.map(({ id, ...node }) => (
    <Dot
      key={id}
      node={node}
      {...{
        onMouseEnter: handleMouseEnter,
        onMouseMove: handleMouseMove,
        onMouseLeave: handleMouseLeave,
      }}
    />
  ));
};

export default Dots;
