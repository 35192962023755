import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import { FaBullseye } from "react-icons/fa";
import { If, Then } from 'react-if';
import { MapContainer } from 'components';
import api from 'api';

export default class extends Component {
    
    state = {
        markers: [],
        center: {
            lat: -23.5505, 
            lng: -46.6333
        }
    };

    async componentDidMount(){
        const { data } = await api.getGeoCaptures();
        const { addresses } = this.props;
        this.setState(
            ({ center }) => {
                const markers = data.map(
                    ({ latitude, longitude, deviceType: type, ...marker }) => 
                        ({
                            position: {
                                lat: parseFloat(latitude),
                                lng: parseFloat(longitude)
                            },
                            type,
                            ...marker
                        })
                ).concat(
                    (addresses|| []).map(
                        ({ latitude, longitude, type }) => 
                            ({
                                position: {
                                    lat: parseFloat(latitude),
                                    lng: parseFloat(longitude)
                                },
                                type
                            })
                    )
                );
                return {
                    center: !!markers.length ? markers[0] : center,
                    markers: markers
                };
            }
        );
    }

    static getDerivedStateFromProps({ addresses=[] }, { center, markers }){
        if(markers.filter(({ type }) => ['HOME', 'BUSINESS', 'ALTERNATIVE'].includes(type)).length || !addresses.length){
            return null;
        }
        const castAddress = (addresses|| []).map(
            ({ latitude, longitude, type }) => 
                ({
                    position: {
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude)
                    },
                    type
                })
        );
        const newMarkers = [ ...markers, ...castAddress ];
        return {
            markers: newMarkers,
            center: newMarkers.length ? newMarkers[0].position : center
        }
    }

    render(){
        const { markers, center } = this.state;
        return (
            <Card style={{ borderTop: 0, height: 750 }}>
                <CardBody>
                    <Row>
                        <Col style={{ display: 'flex' }}>
                            <FaBullseye color="#eb5517" size="33px" style={{ marginRight: 20 + 'px' }} />
                            <h3>Mapa de Localizações</h3>
                        </Col>
                    </Row>
                    <span style={{ position: 'absolute', left: '2%', fontSize: '1.75rem', color: 'darkGray', zIndex: 100 }}>
                        <Label style={{ zIndex: 100, backgroundColor: 'white', fontSize: 25 }}>&nbsp;Registros: {markers.length}&nbsp;</Label>
                    </span>
                    <br />
                    <Row style={{paddingTop: 10 + 'px'}}>
                        <Col className="tab-border" style={{ minHeight: 650 + 'px', padding: '17px 10px' }}>
                            <If condition={markers.length > 0}>
                                <Then>
                                    <MapContainer markers={markers} zoom={6} center={center} style={{ height: 95 + '%' }}
                                        legendStyle={{ marginTop: 17 + 'px' }}/>
                                </Then>
                            </If> 
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}
