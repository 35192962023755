import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FaBullseye } from "react-icons/fa";
import { If, Then } from 'react-if';
import HitLine from './HitLine';
import api from 'api';
import axios from 'axios';

const Hit = ({ isMobile }) => {
    const [ captures, setCaptures ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const onLoadData = useCallback(async (amount, page) => {
        setLoading(true)
        const { data: { content, size } } = await api.getCaptures(amount, page);
        setTotal(size);
        if (page === 0) {
            setCaptures(content)
        }
        const images = await axios.all(content.map(({ id }) => api.getImage(id)));
        const imagesCaptures = content.map((item, index) => 
            ({ ...item, imageUrl: images[index] })
        )
        console.log(page);
        if (page === 0) {
            console.log(imagesCaptures);
            setCaptures(imagesCaptures)
        } else {
            setCaptures(items => {
                console.log(items);
                console.log(imagesCaptures);
                console.log([...items, ...imagesCaptures]);
                return [...items, ...imagesCaptures]
            });
        }
        setLoading(false)
    }, [])
    useEffect(() => {
        onLoadData(20, 0)
    }, [onLoadData]);
    return (
        <Card style={{ borderTop: 0 }}>
            <CardBody>
                <Row>
                    <Col style={{ display: 'flex' }}>
                        <FaBullseye color="#eb5517" size="33px" style={{ marginRight: 20 + 'px' }} />
                        <h3>Captura</h3>
                    </Col>
                </Row>
                <If condition={!!captures.length}>
                    <Then>
                        <HitLine {...{ isMobile, captures, total, loading, onLoadData }}/>
                    </Then>
                </If>
            </CardBody>
        </Card>
    );
}

export default Hit
