import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;

export const setDefaultHeader = (header, value) => {
    if(!!value){
        axios.defaults.headers.common[header] = value;
    } else {
        delete axios.defaults.headers.common[header]
    }
};

export const hasHeader = header => 
    axios.defaults.headers.common.hasOwnProperty(header);
