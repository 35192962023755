import React, { Fragment, useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import { BeatLoader } from "react-spinners";
import { If, Then, Else } from "react-if";
import { DataViewer, MapContainer } from "components";
import { debounce, formatAddress } from "utils";

const HitLine = ({ total, isMobile, loading, captures, onLoadData }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const afterChange = (currentSlide) => {
    if (!loading && total > 20 && captures.length < total && currentSlide >= 19 && (currentSlide + 1 === captures.length)) {
      onLoadData(10, captures.length / 10)
    }
  }

  const nextSlide = () => {
    if (!loading) {
      if (currentSlide < total - 1) {
        const nextSlide = currentSlide + 1
        setCurrentSlide(nextSlide)
        afterChange(nextSlide)
      } else if (currentSlide === total - 1) {
        setCurrentSlide(0)
      }
    }
  }

  const prevSlide = () => {
    if (currentSlide > 0){
      setCurrentSlide(currentSlide - 1)
    }
  }

  const updateCurrentSlide = debounce(100, (index) => {
    if (!loading && isMobile) {
      if (currentSlide < index) {
        nextSlide();
      } else if (currentSlide > index) {
        prevSlide();
      }
    }
  });

  return (
    <>
      <span
        style={{
          position: "absolute",
          left: 5.5 + "%",
          width: 35 + "%",
          fontSize: 1.75 + "rem",
          color: "darkGray",
          zIndex: 100,
        }}
      >
        <Label
          style={{ zIndex: 100, backgroundColor: "white", fontSize: 25, paddingRight: 6 }}
        >
          {currentSlide + 1} de {total}
        </Label>
      </span>
      <span
        style={{
          position: "absolute",
          right: 5.5 + "%",
          paddingTop: 2.5 + "px",
          fontSize: 1.75 + "rem",
          color: "darkGray",
          zIndex: 110,
          backgroundColor: "white",
        }}
      >
        <BeatLoader loading={loading} color="#eb5517" />
      </span>
      <br />
      <Row>
        <Col>
          <If condition={
            !isMobile &&
            (currentSlide !== 0 || captures.length === total) &&
            total > 1
          }>
            <Then>
              <FaArrowCircleLeft className="arrow-button" onClick={prevSlide} style={{ right: "5%" }}/>
            </Then>
          </If>
        </Col>
        <Col md="11" className="tab-border" style={{ zIndex: 10 }}>
          <Carousel
            emulateTouch={isMobile}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            showIndicators={false}
            infiniteLoop={true}
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
          >
            {captures.map(
              ({ latitude, longitude, id, deviceType: _, ...line }) => {
                const position = {
                  lat: parseFloat(latitude),
                  lng: parseFloat(longitude),
                };
                return (
                  <Fragment key={id}>
                    <Row>
                      <Col>
                        <br />
                        <FormGroup style={{ paddingRight: 1 + "%" }}>
                          <DataViewer
                            label="Data/Hora"
                            value={new Date(
                              line.dateCaptured
                            ).toLocaleString()}
                          />
                          <DataViewer
                            label="Endereço"
                            value={formatAddress(line)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" sm="12">
                        <If condition={!!line.imageUrl}>
                          <Then>
                            <img alt="" src={line.imageUrl} />
                          </Then>
                          <Else>
                            <span
                              style={{
                                position: "absolute",
                                right: 43.5 + "%",
                                paddingTop: 2.5 + "px",
                                fontSize: 8.75 + "rem",
                                color: "darkGray",
                                zIndex: 110,
                                backgroundColor: "white",
                              }}
                            >
                              <BeatLoader loading={true} color="#eb5517" />
                            </span>
                          </Else>
                        </If>
                      </Col>
                      <Col md="6" sm="12" style={{ minHeight: 35 + "vmin" }}>
                        <MapContainer
                          zoom={14}
                          center={position}
                          markers={[{ type: "HIT", position, ...line }]}
                        />
                        <br />
                      </Col>
                    </Row>
                    <br />
                  </Fragment>
                );
              }
            )}
          </Carousel>
        </Col>
        <Col>
          <If condition={!isMobile && total > 1}>
            <Then>
              <FaArrowCircleRight className="arrow-button" onClick={nextSlide} style={{ left: "5%", pointerEvents: loading ? 'none': 'auto' }}/>
            </Then>
          </If>
        </Col>
      </Row>
    </>
  );
}

export default HitLine
