import React, { useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, OverlayView } from "@react-google-maps/api";
import { If, Then, Else } from 'react-if';
import { business, camera, home, mobile, app, target } from "./img";
import Info from "./Info";
import './map.sass'

const getIcon = type => {
  switch (type) {
    case 'FIXED': return camera;
    case 'MOBILE': return mobile;
    case 'APP': return app;
    case 'HOME': return home;
    case 'BUSINESS':
    case 'WORK': return business;
    default: return target;
  }
}

export default (props) => {
  const { markers } = props
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_KEY_MAP,
  })
  
  const [infoIdx, setInfoIdx] = useState(null)
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(2)
  
  const googleMap = useRef(null)
  
  const onLoad = function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    const { lat: latN, lng: lngN } = bounds.getNorthEast().toJSON();
    const { lat: latS, lng: lngS } = bounds.getSouthWest().toJSON();
    const distance = Math.sqrt(Math.pow(latN - latS, 2) + Math.pow(lngN - lngS, 2));
    map.fitBounds(bounds);
    const newCenter = bounds.getCenter();
    const newZoom = distance > 50 ? 3 :
      distance > 25 ? 4 :
        distance > 10 ? 5 :
          distance > 5 ? 6 :
            distance > 3 ? 7 :
              distance > 1 ? 9 :
                11
    setTimeout(() => {
      setZoom(newZoom)
      setCenter(newCenter)
    }, 500)
  }
  
  let timeout;
  
  const onMouseOut = () => {
    const info = document.getElementById(infoIdx);
    timeout = setTimeout(() => {
      setInfoIdx(null)
    }, 300);
    if (info) {
      info.onmouseover = () => clearTimeout(timeout);
      info.onmouseout = () => onMouseOut();
    }
  }
  
  
  return (

    <If condition={isLoaded}>
      <Then>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        zoom={zoom}
        center={center}
        ref={googleMap}
        onLoad={onLoad}
      >
        {markers.map(({ position, type }, index) => {
          return (
            <Marker
              key={index}
              position={position}
              onMouseOver={() => {
                clearTimeout(timeout)
                setInfoIdx(index)
              }}
              onMouseOut={onMouseOut}
              icon={getIcon(type)}
            />
          )
        })}
        {markers.map((marker, index) => (
          <If condition={index === infoIdx} key={index}>
            <Then>
              <OverlayView
                position={marker.position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Info id={index} {...marker}/>
              </OverlayView>
            </Then>
          </If>
        ))}
      </GoogleMap>
      </Then>
      <Else>
        <></>
      </Else>
    </If>
  )
}