import React from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import './dataViewer.sass';

export default ({label, value}) => (
    <InputGroup>
        <InputGroupAddon addonType="prepend">
            <InputGroupText>{label}</InputGroupText>
        </InputGroupAddon>
        <Input value={value || 'Dado não encontrado'} disabled style={{ fontStyle: value ? 'normal' : 'oblique', color: value ? '': '#555' }} />
    </InputGroup>
)