import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Container, Row, Col } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import { If, Then, Else } from 'react-if';
import { EdossierPage, ForbiddenPage } from './pages';
import { setDefaultHeader, hasHeader } from './http';
import { Header } from './components';
import './App.sass';

const authHeader = 'Authorization';
let hasToken = false;

export default () => {
  const [ loading, setLoading ]= useState(true);
  if(!hasHeader(authHeader)) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    setDefaultHeader(authHeader, 'Bearer ' + token);
    hasToken = !!token;
  }
  if(!hasToken && loading){
    setLoading(false);
  }
  return (
    <LoadingOverlay active={loading} spinner={<BeatLoader color="#eb5517"/>}
      styles={{ wrapper: base => ({...base, overflow: loading ? 'hidden' : 'auto'})}}>
      <Header />
      <Container fluid style={{ height: (window.innerHeight * 0.9) }} >
        <br />
        <Row>
          <Col md={{ size: 10, offset: 1 }} sm>
            <If condition={hasToken}>
              <Then>
                <EdossierPage afterLoading={() => setLoading(false)}/>
              </Then>
              <Else>
                <ForbiddenPage/>
              </Else>
            </If>
          </Col>
        </Row>
      </Container>
    </LoadingOverlay>
  )
};
