import React, { Component, Fragment } from 'react';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Carousel } from "react-responsive-carousel";
import { If, Then, Else } from 'react-if';
import { MapContainer, DataViewer } from 'components';
import { formatAddress } from 'utils';
import { AddressType } from 'model';

export default class extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentSlide: 0,
            isMobile: props.isMobile
        }
    }

    next = () =>
        this.setState(
            ({ currentSlide }) => 
                ({ currentSlide: (currentSlide + 1) % (this.props.addresses.length) })
        );

    prev = () => 
        this.setState(
            ({ currentSlide }) => 
                currentSlide > 0 ? 
                    { currentSlide: (currentSlide - 1) % (this.props.addresses.length - 1) } :
                    { currentSlide: this.props.addresses.length -1 }
        );
    
    updateCurrentSlide = index => {
        const { currentSlide } = this.state;
        if (currentSlide < index) {
            this.next();
        } else if (currentSlide > index) {
            this.prev();
        }
    }

    render(){
        const { addresses } = this.props;
        const total = addresses.length;
        const { currentSlide, isMobile } = this.state;
        return (
            <If condition={total === 0}>
                <Then>
                    <Row>
                        <Col>
                            <DataViewer label="Endereço" value='Registro Indisponível'/> 
                            <DataViewer label="Mapa" value='Registro Indisponível'/>
                        </Col>
                    </Row> 
                </Then>
                <Else>

                    <span style={{ position: 'absolute', left: '5%', width: 40 + '%', fontSize: '1.75rem', color: 'darkGray', zIndex: 100 }}>
                        <Label style={{ zIndex: 100, backgroundColor: 'white', fontSize: 25 }} >&nbsp;{currentSlide + 1} de {total}&nbsp;</Label>
                    </span>
                    <br />
                    <Row >
                        <Col>
                            <If condition={!isMobile && total > 1}>
                                <Then>
                                    <FaArrowCircleLeft style={{ right: '5%' }} className='arrow-button' onClick={this.prev}/>
                                </Then>
                            </If>
                        </Col>
                        <Col md="11" className="tab-border" >
                            <br />
                            <Carousel showStatus={false} showArrows={false} emulateTouch={isMobile} showIndicators={false} infiniteLoop={true} selectedItem={currentSlide} onChange={this.updateCurrentSlide} showThumbs={false}>
                                {
                                    addresses.map(({latitude, longitude, type, ...address}, index) => {
                                        const position = {
                                            lat: parseFloat(latitude),
                                            lng: parseFloat(longitude)
                                        };
                                        return (
                                            <Fragment key={index}>
                                                <FormGroup style={{ paddingRight: 1 + '%' }}>
                                                    <DataViewer label={AddressType[type]} value={formatAddress(address)}/>
                                                </FormGroup>
                                                <Row>
                                                    <Col style={{ minHeight: 650 + 'px' }}>
                                                        <MapContainer zoom={18} center={position} markers={[{ position, type, ...address }]} 
                                                            style={{width: 96.5 + '%', borderRadius: 1.2 + '%'}}/>
                                                    </Col>
                                                </Row>
                                                <br />
                                            </Fragment>
                                        );
                                    })
                                }
                            </Carousel>
                        </Col>
                        <Col style={{ textAlign: 'center' }} >
                            <If condition={!isMobile && total > 1}>
                                <Then>
                                    <FaArrowCircleRight style={{ left: '5%' }} className='arrow-button' onClick={this.next}/>
                                </Then>
                            </If>
                        </Col>
                    </Row>
                </Else>
            </If>
        )
    }
    
}
