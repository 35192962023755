import axios from 'axios';

export default Object.freeze({
  get: () => axios.get("dossie"),
  getGeoCaptures: () => axios.get("dossie/geoCaptures"),
  getCaptures: (pageSize = 20, page = 0) =>
    axios.get(`dossie/captures?size=${pageSize}&page=${page}`),
  getImage: (id) =>
    new Promise(async (resolve, _) => {
      const { data } = await axios.get(`dossie/capture/${id}/image`, {
        responseType: "blob",
      });
      var reader = new FileReader();
      reader.readAsDataURL(
        new Blob([data], {
          type: "image/jpg",
        })
      );
      reader.onloadend = () => resolve(reader.result);
    }),
  getBehaviors: () => axios.get("dossie/behaviors"),
});