import React, { useState, useLayoutEffect } from "react";
import { Form, TabContent, TabPane, Nav } from "reactstrap";
import { TabSelector } from "components";
import VehicleTab from "./vehicle";
import AddressTab from "./address";
import BehaviorTab from './behavior'
import api from "api";
import HitTab from "./hit";
import MapTab from "./map";
import "./page.sass";

export default ({ afterLoading }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeTab, setActiveTab] = useState("2");
  const [dossier, setDossier] = useState({});
  const toggle = (tab) => activeTab !== tab && setActiveTab(tab);
  useLayoutEffect(() => {
    const fetchData = async () => {
      if (!Object.keys(dossier).length) {
        try {
          const { data } = await api.get();
          afterLoading();
          setDossier(data);
        } catch (e) {
          afterLoading();
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dossier]);

  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth <= 768);
  });

  const { addresses, number, contract } = dossier;
  return (
    <>
      <h5>
        {" "}
        Dossiê: {number} {contract ? "Contrato: " + contract : null}{" "}
      </h5>
      <Form>
        <Nav className="ml-auto" tabs>
          <TabSelector onClick={toggle} tabIndex="2" activeTab={activeTab}>
            Veículo
          </TabSelector>
          <TabSelector onClick={toggle} tabIndex="3" activeTab={activeTab}>
            Endereços
          </TabSelector>
          <TabSelector onClick={toggle} tabIndex="4" activeTab={activeTab}>
            Capturas
          </TabSelector>
          <TabSelector onClick={toggle} tabIndex="5" activeTab={activeTab}>
            Mapa
          </TabSelector>
          <TabSelector onClick={toggle} tabIndex="6" activeTab={activeTab}>
            Comportamento
          </TabSelector>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            <VehicleTab data={dossier} />
          </TabPane>
          <TabPane tabId="3">
            <AddressTab isMobile={isMobile} addresses={addresses} />
          </TabPane>
          <TabPane tabId="4">
            <HitTab isMobile={isMobile} />
          </TabPane>
          <TabPane tabId="5">
            <MapTab addresses={addresses} />
          </TabPane>
          <TabPane tabId="6">
            <BehaviorTab isMobile={isMobile} />
          </TabPane>
        </TabContent>
      </Form>
    </>
  );
};
