import React from 'react';
import { FaBan } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';

export default () => (
    <Row>
        <Col md={{ size: 6, offset: 3 }}>
            <h3 style={{ textAlign: 'center' }}>
                <b>
                    <p>
                        <FaBan color="#eb5517"/>
                        &nbsp;Acesso Negado!&nbsp;
                        <FaBan color="#eb5517"/>
                    </p>
                    <p>
                        Por favor, entrar em contato com administrador do sistema
                    </p>
                </b>
            </h3>
        </Col>
    </Row>
)