export const Gender =  {
    MALE: 'Masculino',
    FEMALE: 'Feminino'
}

export const AddressType = {
    HOME: 'Residencial',
    BUSINESS: 'Comercial',
    ALTERNATIVE: 'Alternativo',
    WORK: 'Comercial'
}

export const CaptureType = {
    FIXED: 'Camera Fixa',
    MOBILE: 'Dispositivo Móvel'
}