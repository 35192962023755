import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { FaBullseye } from "react-icons/fa";
import BehaviorLine from "./BehaviorLine";
import api from "api";

export default ({ isMobile }) => {
  const [behaviors, setBehaviors] = useState([]);
  useEffect(() => {
    (async () => {
      const { data } = await api.getBehaviors();
      setBehaviors(data);
    })();
  }, []);
  return (
    <Card style={{ borderTop: 0 }}>
      <CardBody>
        <Row>
          <Col style={{ display: "flex" }}>
            <FaBullseye
              color="#eb5517"
              size="33px"
              style={{ marginRight: 20 + "px" }}
            />
            <h3>Comportamento</h3>
          </Col>
        </Row>
        <BehaviorLine behaviors={behaviors} />
      </CardBody>
    </Card>
  );
};
