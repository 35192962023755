import React from 'react';
import { Row, Col, FormGroup, Card, CardBody } from 'reactstrap';
import { If, Then, Else } from 'react-if'
import { FaHome } from 'react-icons/fa';
import { DataViewer } from 'components';
import AddressLine from './AddressLine';

export default ({ addresses=[], isMobile }) => (
    <FormGroup>
        <Card style={{ borderTop: 0 }}>
            <CardBody>
                <Row>
                    <Col style={{ display: 'flex' }}>
                        <FaHome color="#eb5517" size="33px" style={{ marginRight: 20 + 'px' }} />
                        <h3>Endereços</h3>
                    </Col>
                </Row>
                <If condition={addresses.length > 0}>
                    <Then>
                        <AddressLine isMobile={isMobile} addresses={addresses}/>
                    </Then>
                    <Else>
                        <Row>
                            <Col>
                                <DataViewer label="Endereço"/> 
                            </Col>
                        </Row>
                    </Else>
                </If>
            </CardBody>
        </Card>
    </FormGroup>
);
