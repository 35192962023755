import React from 'react';
import { Row, Col, FormGroup, Card, CardBody } from 'reactstrap';
import { DataViewer } from 'components';
import { FaCar } from 'react-icons/fa';

const formatter = new Intl.NumberFormat('pt-BR',{
    style:'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

export default ({ data }) => (
    <FormGroup>
        <Card style={{ borderTop: 0 }}>
            <CardBody>
                <Row>
                    <Col style={{ display: 'flex' }}>
                        <FaCar color="#eb5517" size="33px" style={{ marginRight: 20 + 'px' }} />
                        <h3>Dados do Veículo</h3>
                    </Col>
                </Row>
                <DataViewer label="Placa" value={data.plate}/>
                <DataViewer label="Marca" value={data.vehicleBrand}/>
                <DataViewer label="Modelo" value={data.vehicleModel}/>
                <DataViewer label="Ano" value={data.vehicleYear}/>
                <DataViewer label="Cor" value={data.vehicleColor}/>
                <DataViewer label="Local de Registro" value={data.vehicleLicenseRegistry}/>
                <DataViewer label="Valor de Mercado" value={data.vehicleMarketValue && formatter.format(data.vehicleMarketValue)}/>
            </CardBody>
        </Card>
    </FormGroup>
)