import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import cbdLogo from './cbdpage.png';
import './header.sass';

export default () => (
    <Navbar style={{ backgroundColor: '#242323' }} dark expand="md" fixed="fixed">
        <NavbarBrand tag="span" style={{ cursor: 'pointer' }}>
            <img src={cbdLogo} alt='' style={{maxWidth:'280px' , maxHeight:'60px'}} />
        </NavbarBrand>
    </Navbar>
);