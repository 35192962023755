import React from 'react';
import { formatAddress, addressNotBlank } from '../../utils';
import { Row, Col, CardBody, Card, Badge } from 'reactstrap';
import { defaultImage } from './img'

const language = 'pt-BR'

const getType = type => {
  switch (type) {
    case 'FIXED': return 'Cameras Fixas';
    case 'MOBILE': return 'Cameras Móveis';
    case 'APP': return 'CataPlaca Mobile';
    case 'HOME': return 'Endereço Residêncial';
    case 'BUSINESS':
    case 'WORK': return 'Endereço Comercial';
    case 'ALTERNATIVE': return 'Endereço Alternativo';
    default: return 'Tipo de localização não identificado';
  }
}

export default ({ id, type, firstCapture, lastCapture, count, position: { lat, lng }, tagName, orientations, ...address }) => (
  <Card className="p-0" style={{ borderRadius: "13px", width: "300px", height: "100%", overflow: "hidden" }}>
    <CardBody className="p-0">
      <Row id={id}>
        <Col md="4">
          <a href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`} target="_blank"
             rel="noopener noreferrer">
                <span className="fallback" style={{ backgroundImage: `url(${defaultImage})`, height: '100%' }}>
                    <img height="100%" width="100%" alt=""
                         src={`https://maps.googleapis.com/maps/api/streetview?size=200x200&location=${lat},${lng}&fov=90&heading=235&pitch=10&key=${process.env.REACT_APP_KEY_MAP}`}/>
                </span>
          </a>
        </Col>
        <Col>
          <Row style={{ marginLeft: -35 + 'px' }}>
            <Col>
              <h6 style={{ paddingTop: 5 + 'px', fontSize: 14 + 'px' }}>
                <strong>
                  { getType(type) }
                </strong>
              </h6>
            </Col>
          </Row>
          <Row style={{ marginLeft: -35 + 'px' }}>
            <Col>
                    <span className="info-map" style={{ color: '#555' }}>
                      {addressNotBlank(address) && <div>{formatAddress(address)}</div>}
                      {!!count && <div>Capturas realizadas pelo dispositivo: {count}</div>}
                      {!!firstCapture &&
                      <div>Primeira captura - {new Date(firstCapture).toLocaleString(language)}</div>}
                      {!!lastCapture && <div>Última captura - {new Date(lastCapture).toLocaleString(language)}</div>}
                      {!!(orientations||[]).length && (
                        <div>
                          Lados do veiculo capturados:
                          <Row>
                            { orientations.map(orientation => (
                              <Col md="4" key={orientation}>
                                <Badge style={{ fontSize: '1em'}}>
                                  {orientation}
                                </Badge>
                              </Col>
                            )) }
                          </Row>
                        </div>
                      )}
                      {!!tagName && (
                        <div style={{ marginTop: '5px', marginBottom: '2px' }}>
                          Caracteristica do dispositivo:&nbsp;
                          <Badge color="info" style={{ fontSize: '1em'}}>
                            {tagName}
                          </Badge>
                        </div>
                      )}
                    </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);
